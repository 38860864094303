// init global state variable;
window.state = { version: '0.7.17' };

// TEST, stack of functions of "views"
window.state.stack = [];

// init global settings variable
window.state.settings = {};

// init default settings values
window.state.settings.octaveStyle = 'helmholtz_number';

// default mapping for midi channel colors (when sending internal midi to display-types)
window.state.settings.midiChannelColor = {
  red: 1,
  orange: 2,
  yellow: 3,
  green: 4,
  aqua: 5,
  blue: 6,
  purple: 7,
  pink: 8,
};

// TODO: use color code that can be used both in css and to send to illuminated piano keys
window.state.settings.midiColor = {
  red: 'red',
  orange: 'orange',
  yellow: 'yellow',
  green: 'green',
  aqua: 'aqua',
  blue: 'blue',
  purple: 'purple',
  pink: 'pink',
};
