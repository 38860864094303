import firebase from './firebase';
import router from './router';

import log from 'loglevel';
import Review from '../core/Review';
import Syllabus from './Syllabus';
import ExerciseLevels from './ExerciseLevels';

const onsetCurrentUserCallbacks = [];
const displayNames = new Map();

export default class User {
  constructor() {
    this.handlers = [];
    this.sessionId = this.createSessionId();
  }

  createSessionId(length = 32) {
    // Declare all characters
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    // Pick characers randomly
    let str = '';
    for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return str;
  }

  static fromAnonymous() {
    const user = new User();
    user.signingOut = false;
    user.displayName = 'Gäst';
    user.type = 'student';
    user.syllabus = new Syllabus('default');
    user.syllabus.init();
    user.levels = ExerciseLevels.fromAnonymous();
    user.isDevUser = firebase.isDev;
    return user;
  }

  static async fromFirebase(firebaseUser) {
    const user = new User();
    user.signingOut = false;
    user.firebase = firebaseUser;
    const doc = await firebase.db.collection('users').doc(firebaseUser.uid).get();
    if (doc.exists) {
      user.displayName = doc.data().displayName; // do not use firebaseUser.displayName as name change by teacher only affects user document, not firebaseUser;
      user.type = doc.data().type;
      if (user.type === 'teacher' || user.type === 'admin') user.students = doc.data().students;
      if (user.type === 'admin') {
        (() => {
          import(/* webpackChunkName: "ErrorViewer" */ './ErrorViewer').then((module) => {
            const ew = module.default;
          });
        })();
        //const ew = new ErrorViewer();
        document.getElementById('versionNotice').style.display = 'none';
      }
      user.review = Review.fromFirebase(doc.data(), firebaseUser.uid);
      user.isDevUser =
        user.firebase.uid === 'mbhKe5yhspPmHXVBVB2w7u10Aol1' || user.firebase.uid === 'wOXXbkKtdDhLjfu9hTHyodXmRn92';
      user.firebasePing();
      user.firebaseQueries();
      // syllabus also updated with snapshot in firebaseQueries (not here, user.type needs to be awaited...)
      user.syllabus = Syllabus.fromFirebase(doc.data(), firebaseUser.uid);
      user.syllabus.init();
      user.levels = ExerciseLevels.fromFirebase(doc.data().levels, user.firebase.uid);
      user.groupCategory = doc.data().groupCategory;
      user.settings = doc.data().settings ? doc.data().settings : {};
      user.lastProgressionRun = doc.data().lastProgressionRun
        ? doc.data().lastProgressionRun
        : new firebase.firebase.firestore.Timestamp(0, 0);
      user.syllabus.lastProgressionRun = user.lastProgressionRun; // quickfix, should be done in syllabus
      user.lastProgressionImproveRun = doc.data().lastProgressionImproveRun
        ? doc.data().lastProgressionImproveRun
        : new firebase.firebase.firestore.Timestamp(0, 0);
      user.syllabus.lastProgressionImproveRun = user.lastProgressionImproveRun; // quickfix, should be done in syllabus
      user.progressionDuration = doc.data().progressionDuration ? doc.data().progressionDuration : 0;
      user.syllabus.progressionDuration = user.progressionDuration; // quickfix, should be done in syllabus
      user.progressionStart = doc.data().progressionStart
        ? doc.data().progressionStart
        : new firebase.firebase.firestore.Timestamp(0, 0);
      user.syllabus.progressionStart = user.progressionStart; // quickfix, should be done in syllabus
      user.mayChangeSyllabus = doc.data().mayChangeSyllabus ? doc.data().mayChangeSyllabus : false;
    } else {
      // new user
      user.diplayName = firebaseUser.displayName;

      // create a document in users
      const userData = {
        displayName: firebaseUser.displayName,
        type: 'user',
        groupCategory: 'A',
        isDevUser: false,
        activeSyllabus: 'new-user',
        syllabus: {},
        settings: {},
        lastProgressionRun: new firebase.firebase.firestore.Timestamp(Date.now() / 1000, 0),
        lastProgressionRun: new firebase.firebase.firestore.Timestamp(0, 0),
        progressionDuration: 300,
      };
      await firebase.db.collection('users').doc(firebaseUser.uid).set(userData);
      //log.debug('saved', data);

      user.type = 'user';
      user.review = Review.fromFirebase({}, firebaseUser.uid);
      user.isDevUser = false;
      user.firebasePing();
      user.firebaseQueries();

      user.syllabus = Syllabus.fromFirebase({}, firebaseUser.uid);
      user.syllabus.init();
      user.levels = ExerciseLevels.fromFirebase({}.levels, user.firebase.uid);
      user.groupCategory = 'A';

      // goto "choose group page"
      //router.goto('/verifiering');
      router.goto('/hem');
    }

    return user;
  }

  isLoggedIn() {
    return !(this.firebase === undefined || this.firebase === null);
  }

  static setCurrentUser(user) {
    if (this.currentUser) this.currentUser.destruct();
    this.currentUser = user;
    onsetCurrentUserCallbacks.forEach((callback) => callback(user));
  }

  signOut() {
    this.signingOut = true;
    this.firebasePing(true);
  }

  static onSetCurrentUser(callback) {
    if (!onsetCurrentUserCallbacks) onsetCurrentUserCallbacks = [];
    onsetCurrentUserCallbacks.push(callback);
    callback(User.getCurrentUser());
    return () => {
      const index = onsetCurrentUserCallbacks.indexOf(callback);
      onsetCurrentUserCallbacks.splice(index, 1);
    };
  }

  /**
   *
   * @returns User
   */
  static getCurrentUser() {
    return this.currentUser;
  }

  destruct() {
    this.firebase = undefined;
    if (this.handlers) this.handlers.forEach((handler) => handler());
    if (this.todoQuery) this.todoQuery();
  }

  firebasePing(signOut = false) {
    if (this.firebase && (!this.signingOut || signOut === true)) {
      const data = {};
      data['users.' + this.firebase.uid] = {
        lastActive: new firebase.firebase.firestore.Timestamp(Date.now() / 1000, 0),
        displayName: this.displayName,
        active: !signOut,
      };
      firebase.db
        .collection('info')
        .doc('activeUsers')
        .update(data)
        .then((data) => {
          if (!signOut) {
            setTimeout(() => this.firebasePing(), 300000);
          } else {
            firebase.signOut().then(() => {
              window.location.href = '/';
            });
          }
        });
    }
  }

  firebaseQueries() {
    this.loadGroups();
    this.loadTodos();
    this.loadLessonReflections();
    this.loadActiveUsers();
    this.loadPeerJS();
    this.loadUserData();
  }

  loadGroups() {
    let groupsRef = firebase.db.collection('groups');
    if (this.type === 'admin') {
      groupsRef = groupsRef.where('category', 'in', ['A', 'B', 'C', 'D', 'T']);
    } else if (this.type === 'teacher') {
      //groupsRef = groupsRef.where("category", "in", ["A", "B", "C", "D"]);
      return ['ignored'];
    } else {
      groupsRef = groupsRef.where('members', 'array-contains', this.firebase.uid);
    }
    this.handlers.push(
      groupsRef.onSnapshot((snapshot) => {
        this.groups = [];
        snapshot.forEach((group) => {
          this.groups.push(group);
        });
        this.loadMessages();
        if (this.groupsCallbacks) this.groupsCallbacks.forEach((callback) => callback(this.groups));
      })
    );
  }

  loadMessages() {
    // if already subscribed, unsubscribe first (for example if groups have changed)
    if (this.messageQuery) this.messageQuery();
    const recipientIds = [this.firebase.uid];
    if (this.type !== 'teacher' && this.type !== 'admin') {
      // do not load group messages for teacher (would load messages for all groups)
      this.groups.forEach((group, index) => {
        if (index < 9) recipientIds.push('g:' + group.id);
      });
    }
    const messagesRef = firebase.db.collection('messages').orderBy('timestamp', 'desc');
    const query = messagesRef.where('to', 'array-contains-any', recipientIds);
    this.handlers.push(
      query.onSnapshot((messages) => {
        this.messages = [];
        messages.forEach((message) => {
          this.messages.push(message);
        });
        if (this.messagesCallbacks) this.messagesCallbacks.forEach((callback) => callback(this.messages));
      })
    );
    /*this.messageQuery = query.get()
    .then(messages => {
      messages.forEach(message => {
        log.debug('MESSAGE')
        this.messages.push(message);
      })
    })
    .catch(err => {
      log.debug('ERROR', err);
    })*/
  }

  getMessage(id) {
    let data;
    if (this.messages) {
      this.messages.forEach((message) => {
        if (message.id === id) {
          data = message;
          return;
        } else {
          log.debug('No match');
        }
      });
    }
    return data;
  }

  loadTodos() {
    // if already subscribed, unsubscribe first (for example if groups have changed)
    if (this.todoQuery) this.todoQuery();
    const todosRef = firebase.db.collection('todos');
    //const query = todosRef.where('to', 'array-contains-any', recipientIds);
    this.todoQuery = todosRef.onSnapshot((todos) => {
      this.todos = [];
      todos.forEach((todo) => {
        this.todos.push(todo);
      });
      if (this.todoCallbacks) this.todoCallbacks.forEach((callback) => callback(this.todos));
    });
  }

  loadLessonReflections() {
    const lessonReflectionRef = firebase.db.collection('lessonReflections');
    let query;
    if (this.type === 'admin') {
      // show all unread
      query = lessonReflectionRef.where('readByTeacher', '==', false);
    } else {
      //} if (this.type === 'student') {
      // show student's own
      query = lessonReflectionRef.where('userRef', '==', firebase.db.doc('users/' + this.firebase.uid));
    }
    this.handlers.push(
      query.onSnapshot((lessonReflections) => {
        this.lessonReflections = [];
        lessonReflections.forEach((reflection) => {
          this.lessonReflections.push(reflection);
        });
        if (this.lessonReflectionCallbacks)
          this.lessonReflectionCallbacks.forEach((callback) => callback(this.lessonReflections));
      })
    );
  }

  getLessonReflection(id) {
    let data;
    if (this.lessonReflections) {
      this.lessonReflections.forEach((reflection) => {
        if (reflection.id === id) {
          data = reflection;
          return;
        } else {
          log.debug('No match');
        }
      });
    }
    return data;
  }

  static updateMixerChannel(uid, channel) {
    // channel with prefix c is a computer, not a single channel
    if (channel.substr(0, 1) !== 'c') {
      channel = parseInt(channel);
    }
    firebase.db.collection('users').doc(uid).update({
      mixerChannel: channel,
    });
  }

  static updateGroupCategory(uid, category) {
    firebase.db.collection('users').doc(uid).update({
      groupCategory: category,
    });
  }

  static updateName(uid, name) {
    firebase.db.collection('users').doc(uid).update({
      displayName: name,
    });
  }

  static updateIsDuplicate(uid, value) {
    firebase.db.collection('users').doc(uid).update({
      isDuplicate: value,
    });
  }

  static updateInstrument(uid, value) {
    firebase.db.collection('users').doc(uid).update({
      instrument: value,
    });
  }
  static updateSecondaryInstrument(uid, value) {
    firebase.db.collection('users').doc(uid).update({
      secondaryInstrument: value,
    });
  }

  loadActiveUsers() {
    this.activeUsers = [];
    if (this.type === 'admin') {
      /*const usersRef = firebase.db.collection('users');
      const query = usersRef.where('lastActive', '>', new firebase.firebase.firestore.Timestamp((Date.now() / 1000) - 60, 0));
      this.handlers.push(query.onSnapshot(snapshot => {
        this.activeUsers = [];
        snapshot.forEach(user => {
          if ((Date.now() / 1000) - user.data().lastActive.seconds <= 60) this.activeUsers.push(user);
        })
        // sort active users alphabetically
        this.activeUsers.sort((a, b) => a.data().displayName.localeCompare(b.data().displayName));
        if (this.activeUsersCallback) this.activeUsersCallback.forEach(callback => callback(this.activeUsers));
      }));*/
      this.handlers.push(
        firebase.db
          .collection('info')
          .doc('activeUsers')
          .onSnapshot((doc) => {
            this.activeUsers = [];
            const data = doc.data().users;
            if (data) {
              Object.keys(data).forEach((uid) => {
                this.activeUsers.push({
                  id: uid,
                  ...data[uid],
                  active: data[uid].active && Date.now() / 1000 - data[uid].lastActive.seconds <= 310,
                });
              });
              // sort active users alphabetically
              this.activeUsers.sort((a, b) => a.displayName.localeCompare(b.displayName));
              if (this.activeUsersCallback) this.activeUsersCallback.forEach((callback) => callback(this.activeUsers));
            }
          })
      );
    }
  }

  loadPeerJS() {
    //this.peerJSConnections = [];
    const peerJSRef = firebase.db.collection('peerjs');
    const query = peerJSRef
      .where('to', '==', this.firebase.uid)
      .where('timestamp', '>', new firebase.firebase.firestore.Timestamp(Date.now() / 1000, 0))
      .orderBy('timestamp', 'desc')
      .limit(1);
    this.handlers.push(
      query.onSnapshot((snapshot) => {
        //this.activeUsers = [];
        let index = 0;
        snapshot.forEach((peerJsRequest) => {
          log.debug('[User] PeerJS snapshot', peerJsRequest);
          //this.activeUsers.push(user);
          if (index++ === 0) {
            async function getPeerJS() {
              const { default: _ } = await import(/* webpackChunkName: "peerJS" */ './webrtc/peerJS');
              return _;
            }
            getPeerJS().then((peer) => {
              log.debug('request type', peerJsRequest.data().type);
              if (peerJsRequest.data().type === 'data') {
                peer.openConnection(peerJsRequest.data().connectionId);
              } else {
                peer.openCall(peerJsRequest.data().connectionId, false, true);
              }
            });
          }
        });
        //if (this.activeUsersCallback) this.activeUsersCallback.forEach(callback => callback(this.activeUsers));
      })
    );
  }

  // really should be rewritten, but alas...
  loadUserData() {
    this.handlers.push(
      firebase.db
        .collection('users')
        .doc(this.firebase.uid)
        .onSnapshot((doc) => {
          const data = doc.data();
          if (data.syllabus && this.syllabus) this.syllabus.updateFromFirebase(data.syllabus);
          if (data.levels) this.levels.updateFromFirebase(data.levels);
        })
    );
  }

  updateSetting(key, value) {
    console.log('updateSetting metronome', key, value, firebase.isAmi);
    if (!firebase.isAmi) return;
    this.settings[key] = value;
    firebase.db
      .collection('users')
      .doc(this.firebase.uid)
      .set(
        {
          settings: {
            [key]: value,
          },
        },
        { merge: true }
      );
  }

  onLoadGroups(callback) {
    if (!this.groupsCallbacks) this.groupsCallbacks = [];
    this.groupsCallbacks.push(callback);
    callback(this.groups);
    return () => {
      const index = this.groupsCallbacks.indexOf(callback);
      this.groupsCallbacks.splice(index, 1);
    };
  }

  onLoadMessages(callback) {
    if (!this.messagesCallbacks) this.messagesCallbacks = [];
    this.messagesCallbacks.push(callback);
    callback(this.messages);
    return () => {
      const index = this.messagesCallbacks.indexOf(callback);
      this.messagesCallbacks.splice(index, 1);
    };
  }

  onLoadActiveUsers(callback) {
    if (!this.activeUsersCallback) this.activeUsersCallback = [];
    this.activeUsersCallback.push(callback);
    callback(this.activeUsers);
    return () => {
      const index = this.activeUsersCallback.indexOf(callback);
      this.activeUsersCallback.splice(index, 1);
    };
  }

  onLoadTodo(callback) {
    if (!this.todoCallbacks) this.todoCallbacks = [];
    this.todoCallbacks.push(callback);
    callback(this.todos);
    return () => {
      const index = this.todoCallbacks.indexOf(callback);
      this.todoCallbacks.splice(index, 1);
    };
  }

  onLoadLessonReflections(callback) {
    if (!this.lessonReflectionCallbacks) this.lessonReflectionCallbacks = [];
    this.lessonReflectionCallbacks.push(callback);
    callback(this.lessonReflections);
    return () => {
      const index = this.lessonReflectionCallbacks.indexOf(callback);
      this.lessonReflectionCallbacks.splice(index, 1);
    };
  }

  getUserGroups(userUID) {
    const userGroups = [];
    this.groups.forEach((group) => {
      const data = group.data();
      if (data.members && data.members.includes(userUID)) userGroups.push(group);
    });

    return userGroups;
  }

  updateProgressionStart() {
    this.progressionStart = new firebase.firebase.firestore.Timestamp(Date.now() / 1000, 0);
    this.syllabus.progressionStart = this.progressionStart; // quickfix, should be done in syllabus
    firebase.db.collection('users').doc(this.firebase.uid).set(
      {
        progressionStart: this.progressionStart,
      },
      { merge: true }
    );
  }

  updateLastProgressionRun() {
    this.lastProgressionRun = new firebase.firebase.firestore.Timestamp(Date.now() / 1000, 0);
    this.syllabus.lastProgressionRun = this.lastProgressionRun; // quickfix, should be done in syllabus
    firebase.db.collection('users').doc(this.firebase.uid).set(
      {
        lastProgressionRun: this.lastProgressionRun,
      },
      { merge: true }
    );
  }

  updateLastProgressionImproveRun() {
    this.lastProgressionImproveRun = new firebase.firebase.firestore.Timestamp(Date.now() / 1000, 0);
    this.syllabus.lastProgressionImproveRun = this.lastProgressionImproveRun; // quickfix, should be done in syllabus
    firebase.db.collection('users').doc(this.firebase.uid).set(
      {
        lastProgressionImproveRun: this.lastProgressionImproveRun,
      },
      { merge: true }
    );
  }

  static setProgressionDuration(uid, duration) {
    console.log('setProgressionDuration metronome', uid, duration);
    firebase.db.collection('users').doc(uid).set(
      {
        progressionDuration: duration,
      },
      { merge: true }
    );
  }

  static async displayNameFromRef(docRef) {
    if (!displayNames.has(docRef.id)) {
      const user = await docRef.get();
      displayNames.set(user.id, user.data().displayName);
    }
    return displayNames.get(docRef.id);
  }

  static async getUserInArray(filter) {
    if (!filter || !Array.isArray(filter) || (Array.isArray(filter) && filter.length === 0)) return null;
    const users = [];
    for (let i = 0; i < filter.length; i++) {
      const doc = await firebase.db.collection('users').doc(filter[i]).get();
      if (doc.exists) users.push(doc);
    }
    return users;
  }
}
